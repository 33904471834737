.c-single-image-block{
    position: relative;
    input{
        position: absolute;
        top:0;
        left:0;
        height: 0;
        width:0;
        padding:0;
    }
    label{
        display: inline-block;
        position: relative;
        cursor: pointer;
        margin:0;
        .image{
            display: inline-block;
            height: 140px;
            width: 140px;
            background-color: #dedede;
            border-radius: 4px;

            background:#dedede url('../../assets/images/icon-image-fallback.svg') no-repeat center center/ 50%;

        }
        .text{
            font-size: 12px;
            position: absolute;
            bottom:7px;
            left:0;
            right:0;
            padding:5px 10px;
            background-color: rgba(#000,0.5);
            z-index:1;
            display: block;
            color:#fff;
            text-align: center;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
    &.small{
        @apply inline-block;
        label{
            height:100px ;
            .image{
          
                width:100px;
                height:100px ;
            }
            .text{
                top:0;
                left:0;
                bottom:0;
                font-size: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
            }
        }
    }
}